import React from 'react';
import PropTypes from 'prop-types';
import CookieConsent from "react-cookie-consent";

import './layout.less';
import Spacer from './spacer';

const Layout = ({ children }) => {
  return (
    <>
      <main>{children}</main>
      <Spacer noPrint />
      <small className="light-font">2020 Kamil Wysocki</small>
      <CookieConsent
        location="bottom"
        buttonText="Akceptuję"
        style={{ background: 'rgba(242, 242, 242)', color: 'hsla(0,0%,0%,0.73)', fontFamily: '"Roboto Slab", serif' }}
        buttonStyle={{ background: 'rgba(242, 242, 242)', color: 'hsla(0,0%,0%,0.73)', border: '2px solid rgba(0,0,0,0.73)', fontSize: "14px" }}
        expires={150}
      >
        Ta strona wykorzystuje pliki cookies. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich użycie.
      </CookieConsent>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
