import React from 'react';

import './spacer.less';

const Spacer = ({ noPrint }) => {
  const classes = noPrint ? 'no-print spacer' : 'spacer';
  return <div className={classes}></div>;
};

export default Spacer;
