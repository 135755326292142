import React from 'react';
import { Link } from 'gatsby';
import { SocialIcon } from 'react-social-icons';

import './menu.less';
import Logo from './logo';
import Spacer from './spacer';
import { lightColor } from './variables';

const Menu = () => {
  return (
    <section id="menu">
      <Logo />
      <Spacer noPrint />
      <ul className="no-print">
        <li>
          <Link to="/">Strona główna</Link>
        </li>
        <li>
          <Link to="/wszystkie">Wszystkie</Link>
        </li>
        <li>
          <Link to="/kategorie">Kategorie</Link>
        </li>
        <li>
          <Link to="/szukaj">Szukaj</Link>
        </li>
        <li>
          <Link to="/tagi">Tagi</Link>
        </li>
        <li>
          <Link to="/ostronie">O stronie</Link>
        </li>
        <li>
          <SocialIcon
            url="http://instagram.com/cookbook.kw"
            bgColor={lightColor}
            style={{
              width: '1.2rem',
              height: '1.2rem',
            }}
          />
        </li>
      </ul>
      <Spacer noPrint />
    </section>
  );
};

export default Menu;
