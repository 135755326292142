import React from 'react';

import './logo.less';
import { Link } from 'gatsby';

const Logo = () => {
  return (<Link to="/" style={{ textDecoration: 'none' }}>
    <span id="logo">
      COOKBOOK
      <span id="logo-url" className="light-font">
        <span className="hide-on-mobile">.</span>KAMILWYSOCKI.COM
      </span>
    </span>
  </Link>);
};

export default Logo;
